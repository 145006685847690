<template>
  <div class="explain">
    <header class="title">提料结算</header>
    <div class="content">
      <div class="icon"></div>
      <h3>结算申请提交成功</h3>
      <div class="text">我们将尽快为您审核,请留意短信通知</div>
      <div class="checkMarket" @click="checkMarket">
        查看行情
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    checkMarket(){
      this.$router.push({
        name:'home'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.explain{
  width: 100%;
}
.title{
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  border-bottom: 1px solid #ccc;
}
.content{
  width: 100%;
  padding: 0 80px;
}
.icon{
  width: 140px;
  height: 140px;
  //border-radius: 50%;
  margin: 40px 228px;
  background: url('~@/assets/images/pic_success@2x.png') no-repeat;
  background-size: 100% 100%;
}
h3{
  color: #1a1a1a;
  font-size: 30px;
  text-align: center;
}
.text{
  color: #ccc;
  text-align: center;
  font-size: 28px;
}
.checkMarket{
  margin-top: 80px;
  width: 100%;
  padding: 18px 0;
  text-align: center;
  color: #fff;
  background-color: #ff2d2e;
  border-radius: 12px;
  font-size: 30px;
}
</style>